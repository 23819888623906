import Style from './Navbar.module.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useState, useEffect, useRef } from 'react'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuRef = useRef(null)
  const burgerIcon = useRef(null)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) &&
        burgerIcon.current && !burgerIcon.current.contains(event.target)) {
      setIsMenuOpen(false)
    }
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)')

    const handleScreenSizeChange = (event) => {
      if (event.matches) {
        setIsMenuOpen(false)
      }
    }

    mediaQuery.addEventListener('change', handleScreenSizeChange)

    if (mediaQuery.matches) {
      setIsMenuOpen(false)
    }

    return () => {
      mediaQuery.removeEventListener('change', handleScreenSizeChange)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [])

  return (
    <nav className={Style.navbar} aria-label='Main menu'>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-3 d-flex justify-content-md-start justify-content-center">
            <Link onClick={closeMenu} to="/"><img className={Style.logo} src="images/willowi-logos02-1@2x.png" alt="Return to home page" /></Link>
          </div>
          <div className="col-lg-6 col-md-9">
            <FontAwesomeIcon ref={burgerIcon} className={Style.menuButton} onClick={toggleMenu} icon={faBars} />
            <div ref={menuRef} className={`${Style.menu} d-md-flex ${isMenuOpen ? 'd-block' : 'd-none'}`}>
              <Link onClick={closeMenu} to="/about">OUR STORY</Link>
              <a onClick={closeMenu} href="https://shop.willowandi.co/collections/all" target="_blank">PRODUCTS</a>
              <Link onClick={closeMenu} to="/contact">CONTACT US</Link>
              <Link onClick={closeMenu} to="/faq">FAQ</Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
 
export default Navbar