import Style from './HomeHero.module.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const HomeHero = () => {
  return ( 
    <header className={Style.homeHero} role='banner'>
      <div className="container text-start">
        <h1>No hidden words,<br/><span className={Style.heavy}>just honest love.</span></h1>
        <p>Nurturing bonds with wholesome dog treats & chews.</p>
        <div className="text-center text-lg-start">
        <a
            className="button"
            href="https://shop.willowandi.co/collections/all"
            target="_blank" // Opens in a new tab
            rel="noopener noreferrer" // Prevents security risks
          >
            SHOP NOW
          </a>
        </div>
      </div>
    </header>
   );
}
 
export default HomeHero;